
<template>
  <div id="sidebar">
    <div class="sidebar" :class="menuOpen ? 'visible' : 'invisible'">
      <ul class="margin-t">
        <li v-for="item of menu" :key="item.id" @click="setMenu">
          <a :href="item.path" >
            {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script >
export default {
  name: "sidebar",
  data: () => ({
    menu: [
      {
        name: "YIPI",
        path: "#yipi",
        id: 1,
      },
      {
        name: "ACERCA DE",
        path: "#acerca",
        id: 2,
      },
      {
        name: "INVERSIONISTAS",
        path: "#inversionistas",
        id: 3,
      },
      {
        name: "ALIADOS",
        path: "#aliados",
        id: 4,
      },
      {
        name: "VIAJES",
        path: "#viajes",
        id: 5,
      },
      {
        name: "EMISIONES",
        path: "#emisiones",
        id: 6,
      },
      {
        name: "ENTREGAS",
        path: "#entregas",
        id: 7,
      },
     {
        name: "PUNTOS YIPI",
        path: "#puntoyipi",
        id: 8,
      },

      {
        name: "CONDUCTORES",
        path: "#conductores",
        id: 9,
      },
            {
        name: "TÉRMINOS Y CONDICIONES",
        path: "/terminos-condiciones",
        id: 10,
      },
      {
        name: "AYUDA",
        path: "/ayuda",
        id: 11,
      },
      {
        name: "PREGUNTAS FRECUENTES",
        path: "/faqs",
        id: 12,
      },
    ],
    icons: {},
  }),
  props: ["menuOpen","setMenu"],
};
</script>
<style  scoped>
.sidebar {
  position: fixed;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 40px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 285px;
  height: 98%;
  background-color: #00f0b7;
  z-index: 1000;
  transition: ease-out 0.6s;
  margin-left: 10px;
}

.visible {
  right: 0;
  opacity: 1;
}
.invisible {
  right: -250px;
  opacity: 0;
}

.sidebar ul a {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 45px;
  font-size: 1.8rem;
  font-family: BebasNeue;
  color: white;

  padding-left: 25px;
}

.sidebar ul li:hover {
  background-color: white;

}
.sidebar ul li:hover a {
  color: #00f0b7;
 
}
.margin-t {
  margin-top: 75px;
}
</style>
 
 

