<template>
  <div>
    <v-btn
      class="fab-top-right"
      elevation="2"
      fab
      color="#00EA90"
      small
      @click="setMenu"
    >
      <v-icon> $menuHamburguesa </v-icon>
    </v-btn>
    <Sidebar :menuOpen="menuOpen" :setMenu="setMenu"></Sidebar>
    <div
      class="drawer-mask"
      :style="{ width: menuOpen ? '100vw' : '0', opacity: menuOpen ? 0.2 : 0 }"
      @click="setMenu"
    ></div>
  </div>
</template>


<script>
import Sidebar from "../components/Sidebar";

export default {
  name: "nav-drawer",

  components: {
    Sidebar,
  },
  data: () => ({
    menuOpen: false,
  }),
  methods: {
    setMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
<style scoped>

.drawer-mask {
  position: fixed;
  z-index: 999;
  width: 0;
  height: 100vh;
  background-color: rgb(187, 183, 183);
}
.fab-top-right {
  position: fixed;
  right: 40px;
  top: 30px;
  z-index: 10000;
}

.v-btn::before {
  background-color:#ffffff;
}
 @media(min-width: 700px){
.fab-top-right {
  position: fixed;
  right: 50px;
  top: 30px;
  z-index: 10000;
}
}
</style>